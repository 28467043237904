import React, { useCallback, useRef, useState } from 'react';

import { Portal } from 'components/portal/portal';
import { ServicesItem } from 'components/services-item/services-item';
import { ServicesButton } from 'components/services-dropdown/services-button/services-button';
import { SERVICES } from 'assets/data/services';
import { AppMessages } from '../../i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import styles from './services-dropdown.module.scss';

export const ServicesDropdown = () => {
  const { formatMessage } = useLocale();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <>
      <ServicesButton onClick={handleClick} buttonRef={ref} />
      {isOpen && (
        <Portal onClose={handleClick} anchorRef={ref}>
          <div className={styles.servicesDropdown}>
            <div className={styles.content}>
              <span className={styles.title}>
                {formatMessage({
                  id: AppMessages['header.services'],
                })}
              </span>
              <div className={styles.servicesWrapper}>
                {SERVICES.map(({ name, img, url }) => (
                  <ServicesItem label={name} img={img} link={url} key={name} onClick={handleClick} />
                ))}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};
