import React from 'react';
import { StaticImageData } from 'next/image';

import styles from './services-item.module.scss';

type ServicesItemProps = {
  link?: string;
  img: StaticImageData;
  label: string;
  onClick?: () => void;
};

export const ServicesItem = ({ link, img, label, onClick }: ServicesItemProps) => {
  return (
    <a href={link} onClick={onClick} target="_blank" rel="noreferrer" className={styles.servicesItem}>
      <img src={img.src} alt={label} className={styles.servicesImg} />
    </a>
  );
};
