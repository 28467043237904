import { StaticImageData } from 'next/image';

import { AppMessages } from 'i18n/messages';
import ForsahIcon from 'assets/images/services/forsah-desktop.svg';
import BahrIcon from 'assets/images/services/bahr-desktop.svg';
import TojjarIcon from 'assets/images/services/tojjar-desktop.svg';
import ZaddIcon from 'assets/images/services/zadd-desktop.svg';

export const SERVICES: { name: string; translationKey: keyof typeof AppMessages; url: string; img: StaticImageData }[] =
  [
    { name: 'Forsa', translationKey: 'services.forsah', url: process.env.NEXT_PUBLIC_FORSAH_URL, img: ForsahIcon },
    { name: 'Bahr', translationKey: 'services.bahr', url: process.env.NEXT_PUBLIC_BAHR_URL, img: BahrIcon },
    {
      name: 'Tojjar',
      translationKey: 'services.tojjarBooths',
      url: process.env.NEXT_PUBLIC_TOJJAR_URL,
      img: TojjarIcon,
    },
    { name: 'Zadd', translationKey: 'services.zadd', url: process.env.NEXT_PUBLIC_ZADD_URL, img: ZaddIcon },
  ];
